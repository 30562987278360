import { useCallback, useMemo, ChangeEvent, memo } from 'react'

import { TextInput, Table, Button, createStyles } from '@mantine/core'
import { useQuestionStore, NullableQuestionSet, QuestionKind, noptions } from '../model/questions'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { IconGripVertical } from '@tabler/icons'

import { QuestionOptionProps, QuestionSelect } from './QuestionSelect'


const useStyles = createStyles((theme) => ({
  item: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    cursor: 'grab',
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
  },
}))

const kinds: QuestionKind[] = ['binary', 'likert', 'free']

type QuestionOptions = {
  binary: QuestionOptionProps[],
  likert: QuestionOptionProps[],
  free: QuestionOptionProps[]
}

type SubjectInputProps = {
  i: number,
  subject: string,
}

const SubjectInput = memo(({i, subject}: SubjectInputProps) => {
  const setQuestionSetTopic = useQuestionStore(state => state.setQuestionSetTopic)

  const onTopicChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target) setQuestionSetTopic(i, ev.target.value)
  }, [i])

  return (
    <TextInput
      sx={{width: 'auto'}}
      styles={{
        root: {
          width: 'auto',
        },
        input: {
          width: 'auto',
          // '&:active':{
          '&:not(:focus)':{
            border: 'none',
            background: 'none',
          },
        },
      }}
      value={subject}
      onChange={onTopicChange}
    />
  )
})

type QuestionSetRowProps = {
  i: number,
  questionSet: NullableQuestionSet,
  options: QuestionOptions,
}

const QuestionSetRow = memo(({i, questionSet, options}: QuestionSetRowProps) => {
  const {classes} = useStyles()
  const setQuestionSetQuestion = useQuestionStore(state => state.setQuestionSetQuestion)
  const removeQuestionSet = useQuestionStore(state => state.removeQuestionSet)
  const {questions, subject} = questionSet

  const optionData = useMemo(() =>
    kinds.map((kind, qi) => ({
      kind,
      location: {i, qi},
      onChange(v: string | null) {
        setQuestionSetQuestion(i, qi, v)
      },
      options: options[kind],
    }))
  , [i, options])

  const onRemove = useCallback(() => removeQuestionSet(i), [i])

  return (
    <Draggable draggableId={questionSet.id!} index={i}>
    {(provided) =>
      <tr ref={provided.innerRef} {...provided.draggableProps}>
        <td>
          <div className={classes.dragHandle} {...provided.dragHandleProps}>
            <IconGripVertical size={18} stroke={1.5} />
          </div>
        </td>
        {/*<td>{i+1}</td>*/}
        <td>
          <SubjectInput i={i} subject={subject} />
        </td>

        { optionData.map((od, i) =>
          <td key={''+i}>
            <QuestionSelect
              options={od.options}
              value={questions[i]}
              onChange={od.onChange}
              kind={od.kind}
              location={od.location}
            />
          </td>
        )}
        <td>
          <Button w="48px" onClick={onRemove}>-</Button>
        </td>

      </tr>
    }
    </Draggable>
  )
})


export function QuestionSetsTable() {
  const questionSets = useQuestionStore(state => state.allQuestionSets)
  const questions = useQuestionStore(state => state.questions)
  const unusedQuestions = useQuestionStore(state => state.unusedQuestions)
  const addBlankQuestionSet = useQuestionStore(state => state.addBlankQuestionSet)
  const moveQuestionSet = useQuestionStore(state => state.moveQuestionSet)

  const questionOptions = useMemo(() => {
    const options = {binary: [], likert: [], free: []} as QuestionOptions
    const kinds = Object.keys(options) as (keyof typeof options)[]

    for (const q of Object.values(questions)) {
      for (const kind of kinds) {
        if ((q.options?.length || 0) === noptions[kind]) {
          options[kind].push({
            value: q.id,
            label: q.question,
            used: !unusedQuestions.has(q.id),
            question: q,
          })
        }
      }
    }

    return options

  }, [questions, unusedQuestions])


  return (
    <DragDropContext
      onDragEnd={({source, destination}) => {
        if (destination && source.index !== destination.index) {
          console.log('DRAGGED', source, destination)
          moveQuestionSet(source.index, destination.index)
        }
      }}
    >
      <Table sx={{tableLayout: 'fixed'}}>
        <thead>
          <tr>
            <th style={{width: 40}}></th>
            {/*<th style={{width: '2em'}}>#</th>*/}
            <th style={{width: '15%'}}>Topic</th>
            <th>Binary Question</th>
            <th>Likert Question</th>
            <th>Free Question</th>
            <th style={{width: '72px'}}></th>
          </tr>
        </thead>
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) =>
            <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {questionSets.map((qs, i) =>
                <QuestionSetRow key={qs.id} i={i} questionSet={qs} options={questionOptions} />
              )}

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <Button w="48px" onClick={addBlankQuestionSet}>+</Button>
                </td>
              </tr>
              {provided.placeholder}
            </tbody>
          }
        </Droppable>
      </Table>
    </DragDropContext>
  )
}
