import { useEffect, useCallback } from 'react'

import { Paper, Button, Transition } from '@mantine/core'
import { AnimatePresence } from "framer-motion"

import { useScreenStore, Screen } from '../model/screen'
import { useQuestionStore } from '../model/questions'
import { useUpdates } from '../model/updates'
import { useWatchScene, useBlinds } from '../model/blinds'
import { useGradientStore } from '../model/gradients'

import { Home } from '../screens/Home'
import { Consent } from '../screens/Consent'
import { Questions } from '../screens/Questions'
import { useNavigate } from 'react-router'

type AnyComponent = keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>

type Screens = {[k in Screen]: AnyComponent}
const screens: Screens = {
  home: Home,
  consent: Consent,
  questions: Questions
}

const debugButtonStyle = {
  position: 'absolute' as any,
  bottom: '24px', left: '24px',
  border: '2px solid rgba(150, 150, 150, 0.2)',
  borderRadius: '12px',
  color: 'rgba(150, 150, 150, 0.2)',
  mixBlendMode: 'color-burn',
}

function MaybeLockout() {
  const navigate = useNavigate()
  const blindsMode = useBlinds((state) => state.mode)
  useWatchScene(5000)

  useEffect(() => {
    if (blindsMode === 'locked') navigate('/lock')
  }, [blindsMode])


  return <></>
}

const screenStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
}

function Survey() {
  const screen = useScreenStore(state => state.screen)

  const loadQuestions = useQuestionStore(state => state.fetchQuestionConfig)
  const loaded = useQuestionStore(state => state.loaded)
  const chooseQuestions = useQuestionStore(state => state.chooseSequentialQuestions)
  const questionSets = useQuestionStore(state => state.questionSets)
  const userId = useQuestionStore(state => state.userId)
  const updateGradient = useGradientStore(state => state.update)


  useEffect(() => {
    loadQuestions()
  }, [userId])

  useEffect(() => {
    if (loaded) chooseQuestions()
    updateGradient()

  }, [loaded, userId, questionSets])

  const Screen = screens[screen]

  const navigate = useNavigate()
  const goToDebug = useCallback(() => navigate('/lock'), [])

  useUpdates()


  return <>
    <Paper w="100%" h="100%" radius={0} sx={{overflow: 'hidden', position: 'relative'}}>

      <AnimatePresence>
        <Screen
          key={`${screen}-${userId}`}
          style={screenStyle}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
          transition={{ duration: 0.6, ease: 'easeIn' }}
        />
      </AnimatePresence>

      <MaybeLockout />

      { screen === 'consent' &&
        <Button size="xl" variant="outline" sx={debugButtonStyle} onClick={goToDebug}>Debug</Button>
      }
    </Paper>
  </>
}

export default Survey
