import { useCallback, forwardRef } from 'react'

import { Flex, Button, Title, keyframes } from '@mantine/core'
import type { FlexProps } from '@mantine/core'
import { motion } from 'framer-motion'

import { useScreenStore } from '../model/screen'
import { useQuestionStore } from '../model/questions'
import { useGradient } from '../model/gradients'

function HeroText() {
  return <Flex
    justify="center"
    align="center"
    h="100%"
    sx={{pointerEvents: 'none'}}
  >
    <Title
      fz="140px"
      align="center"
      color="black"
      transform="initial"
    >
      What are your<br /> ethical boundaries?
    </Title>
  </Flex>
}

const pulseAnimation = keyframes({
  '0%': { opacity: 1.0 },
  '25%': { opacity: 0.1 },
  '50%': { opacity: 1.0 },
  '100%': { opacity: 1.0 },
})

export const Home = motion(forwardRef<HTMLDivElement, FlexProps>((props: FlexProps, ref) => {
  const start = useScreenStore((state) => state.start)
  const loaded = useQuestionStore((state) => state.loaded)

  const gradient = useGradient()

  const maybeStart = useCallback((ev: React.MouseEvent) => {
    if (ev.target !== ev.currentTarget || !loaded) return
    start()
  }, [loaded])

  return (
    <Flex
      h="100%"
      direction="column"
      justify="space-around"
      py="10vh"
      pos="relative"
      onClick={maybeStart}
      sx={{
        background: `linear-gradient(90deg, ${gradient.from}, ${gradient.to})`,
      }}
      ref={ref}
      {...props}
    >
      <HeroText />
      <Flex justify="center">
          <Button
            size="xl"
            variant="subtle"
            sx={{
              height: '54px',
              fontSize: '54px',
              color: 'black',
              animation: `${pulseAnimation} 2.6s ease-out infinite`,
            }}
            radius="xl"
            onClick={start}
            disabled={!loaded}
          >
            Tap to start
          </Button>
      </Flex>
    </Flex>
  )
}))
