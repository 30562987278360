import create from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { enableMapSet } from 'immer'

enableMapSet()

import { api } from './api'

import type { APIQuestionConfig, QuestionConfig, QuestionSet, NullableQuestionSet, Questions, Question, Answers, APIResponse } from '../../../../shared/types'

export type { QuestionConfig, QuestionSet, NullableQuestionSet, Questions, Question, Answers }

export type QuestionKind = 'binary' | 'likert' | 'free'
export const noptions = {'free': 0, 'binary': 2, 'likert': 5} as const

export type QuestionLocation = {
  i: number,
  qi: number
}

export type QuestionId = string

export type EditQuestionState = {
  kind: QuestionKind,
  question: Partial<Question>,
  originalId?: QuestionId,
  location?: QuestionLocation,
}

export interface QuestionStore {
  version: number,
  questionSets: QuestionSet[],
  allQuestionSets: NullableQuestionSet[],
  questions: Questions,
  unusedQuestions: Set<QuestionId>,
  chosenQuestionSets?: [number, number, number],
  userId: number,
  answers: Answers,
  loaded: boolean,
  questionIndex: number,
  hasChanges: boolean,
  unsavedQuestions: Set<QuestionId>,

  addQuestion: (question: Question) => void,
  editQuestionState: EditQuestionState | null,
  editQuestion: (state: EditQuestionState | null) => void,
  commitEditQuestion: () => void,
  updateEditQuestion: (question: Partial<Question>) => void,
  updateEditQuestionOption: (i: number, value: string | null) => void,
  setEditQuestionKind: (kind: QuestionKind) => void,

  chooseRandomQuestions: () => void,
  chooseSequentialQuestions: () => void,
  replaceSequentialQuestion: (pos: number) => void,

  reset: () => void,
  answer: (key: QuestionId, answer: number | string) => void,

  setQuestionSetTopic: (i: number, topic: string) => void,
  setQuestionSetQuestion: (i: number, qi: number, id: QuestionId) => void,
  moveQuestionSet: (source: number, dest: number) => void,
  removeQuestionSet: (i: number) => void,
  addBlankQuestionSet: () => void,


  fetchQuestionConfig: () => Promise<void>,
  saveQuestionConfig: () => Promise<void>,
}

const sgenId = () => Math.random().toFixed(10).slice(2)
const genId = () => +sgenId()

const shuffle = (a: any[]) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]]
  }
}

const isLength3 = <T>(a: T[]): a is [T, T, T] => a.length === 3

function assertLength3<T>(a: T[]): asserts a is [T, T, T] {
  if (!isLength3(a)) throw new Error('length check failed')
}

const getUnusedQuestions = (questions: Questions, questionSets: NullableQuestionSet[]) => {
  const unused = new Set(Object.keys(questions))

  for (const qs of questionSets) {
    unused.delete(qs.questions[0]!)
    unused.delete(qs.questions[1]!)
    unused.delete(qs.questions[2]!)
  }

  return unused
}

export const trimmedOptions = (options: string[] | null | undefined, kind: QuestionKind): string[] | undefined => {
  if (kind === 'free') return undefined
  if (kind === 'binary') return options?.slice(0, noptions.binary)
  if (kind === 'likert') return options?.slice(0, noptions.likert)

  throw new Error('Unknown kind!')
}

export const isValidQuestion = (q: Partial<Question>, kind: QuestionKind): q is Question => {
  const idValid = q.id != null && q.id.length > 0
  if (!idValid) return false

  const questionValid = q.question != null &&  q.question.length > 0
  if (!questionValid) return false

  const options = trimmedOptions(q.options, kind) || []

  return options.length === noptions[kind] && options.every(x => x.length > 0)
}

export const checkValidQuestionSet =
  (qs: NullableQuestionSet): qs is QuestionSet =>
    !!qs.subject && !!qs.questions[0] && !!qs.questions[1] && !!qs.questions[2]


export const checkValidQuestionSets =
  (qss: NullableQuestionSet[]): qss is QuestionSet[] =>
    qss.every(checkValidQuestionSet)

export const sampleAnswers = async (id: QuestionId, count: number): Promise<number[] | null> => {
  try {
    const params = {questionId: id, count: ''+count}
    return (await api('responses/sample', {params})).data
  }
  catch (_) {
    return null
  }
}

export const useQuestionStore = create(immer<QuestionStore>((set, get) => ({
  version: 0,
  questions: {},
  unusedQuestions: new Set(),
  questionSets: [],
  allQuestionSets: [],
  chosenQuestionSets: undefined,
  questionIndex: 0,
  userId: genId(),
  answers: {},
  loaded: false,
  hasChanges: false,
  editQuestionState: null,
  unsavedQuestions: new Set(),

  reset: () => {
    const questionIndex = (get().questionIndex + 3) % get().questionSets.length

    set({userId: genId(), answers: {}, questionIndex })
  },

  chooseRandomQuestions: () => {
    const qs = get().questionSets

    const indices = Array.from(qs.keys())
    shuffle(indices)

    const chosen: number[] = []
    for (const i of indices) {
      if (chosen.length === 3) break
      if (!qs[i].enabled) continue
      if (chosen.some((j) => qs[i].subject === qs[j].subject)) continue
      chosen.push(i)
    }
    if (!isLength3(chosen)) throw new Error('Not enough questions')

    // console.log('chooseQuestions', chosen)
    set({chosenQuestionSets: chosen})
  },

  chooseSequentialQuestions: () => {
    const len = get().questionSets.length
    const qi = get().questionIndex

    const chosen = [qi, (qi+1) % len, (qi+2) % len]

    assertLength3(chosen)

    set({chosenQuestionSets: chosen})
  },

  replaceSequentialQuestion: (pos: number) => {
    const len = get().questionSets.length

    const questionIndex = (get().questionIndex + 1) % len

    const qi = (questionIndex + 2) % len

    set(state => {
      if (!state.chosenQuestionSets) return
      state.questionIndex = questionIndex

      state.chosenQuestionSets[pos] = qi
    })
  },

  saveQuestionConfig: async () => {
    if (!get().loaded) throw new Error('attempt to set questions before questions are loaded')

    const {version, questions, allQuestionSets} = get()

    if (!checkValidQuestionSets(allQuestionSets)) throw new Error('invalid questionSets')

    const config: APIQuestionConfig = {version, questions, questionSets: allQuestionSets}

    await api.post('questions', config)

    set(state => {
      state.hasChanges = false
      state.unsavedQuestions.clear()
    })
  },

  fetchQuestionConfig: async () => {
    const config: APIQuestionConfig = (await api('questions')).data

    const {version, questions, questionSets: allQuestionSets} = config

    for (const qs of allQuestionSets) {
      qs.id = sgenId()
    }

    const questionSets = allQuestionSets.filter(q => q.enabled)

    const unusedQuestions = getUnusedQuestions(questions, allQuestionSets)

    set({questionSets, unusedQuestions, allQuestionSets, questions, version: version + 1, loaded: true})
  },

  answer: (question_id: QuestionId, answer: number | string) => {
    console.log('updating', question_id, 'to', answer)

    const data: APIResponse = {
      user_id: get().userId,
      question_id,
      answer
    }

    api.post('responses', data)

    set(state => ({
      answers: {...state.answers, [question_id]: answer}
    }))
  },

  setQuestionSetTopic(i: number, topic: string) {
    set(state => {
      state.allQuestionSets[i].subject = topic
      state.hasChanges = true
    })
  },

  setQuestionSetQuestion(i: number, qi: number, id: QuestionId | null) {
    set(state => {
      state.allQuestionSets[i].questions[qi] = id
      state.unusedQuestions = getUnusedQuestions(state.questions, state.allQuestionSets)
      state.hasChanges = true
    })
  },

  moveQuestionSet(source: number, dest: number) {
    set(state => {
      const qs = state.allQuestionSets
      qs.splice(dest, 0, ...qs.splice(source, 1))
      state.hasChanges = true
    })
  },

  removeQuestionSet(i: number) {
    set(state => {
      state.allQuestionSets.splice(i, 1)
      state.unusedQuestions = getUnusedQuestions(state.questions, state.allQuestionSets)
      state.hasChanges = true
    })
  },

  addBlankQuestionSet() {
    set(state => {
      state.allQuestionSets.push({
        id: sgenId(),
        enabled: true,
        subject: '',
        questions: [null, null, null],
      })
      state.hasChanges = true
    })
  },

  addQuestion(question: Question) {
    set(state => {
      if (!state.questions[question.id]) {
        state.unusedQuestions.add(question.id)
        state.unsavedQuestions.add(question.id)
      }

      console.log('addQuestion!', question.id, Object.assign({}, question))

      state.questions[question.id] = Object.assign({}, question)
      state.hasChanges = true
    })
  },

  editQuestion(eqs: EditQuestionState | null) {
    set(state => {
      state.editQuestionState = eqs
    })
  },

  commitEditQuestion() {
    set(state => {
      if (!state.editQuestionState) return
      const {question, location, kind, originalId} = state.editQuestionState

      if (!isValidQuestion(question, kind)) throw new Error('tried to commit invalid edit question')

      question.options = trimmedOptions(question.options, kind)

      if (originalId && originalId !== question.id) {
        if (location) {
          const {i, qi} = location
          state.allQuestionSets[i].questions[qi] = null
        }

        state.unusedQuestions.delete(originalId)
        state.unsavedQuestions.delete(originalId)
        delete state.questions[originalId]
      }

      if (!state.questions[question.id]) {
        state.unusedQuestions.add(question.id)
        state.unsavedQuestions.add(question.id)
      }

      console.log('addQuestion 2!', question.id, Object.assign({}, question))

      state.questions[question.id] = Object.assign({}, question)

      if (location) {
        const {i, qi} = location
        // get().setQuestionSetQuestion(target.i, target.qi, question.id)
        state.allQuestionSets[i].questions[qi] = question.id
        console.log('setting target', i, qi, question.id)
        state.unusedQuestions.delete(question.id)
      }

      state.editQuestionState = null
      state.hasChanges = true
    })
  },


  updateEditQuestion(question: Partial<Question>) {
    set(state => {
      if (state.editQuestionState) {
        Object.assign(state.editQuestionState.question, question)
      }
    })
  },

  setEditQuestionKind(kind: QuestionKind) {
    set(state => {
      if (state.editQuestionState) {
        state.editQuestionState.kind = kind
      }
    })
  },

  updateEditQuestionOption(i: number, value: string | null) {
    set(state => {
      const eqs = state.editQuestionState
      if (!eqs) return

      let options = eqs.question.options
      if (!options) {
        if (value == null) return

        options = eqs.question.options = []
      }

      if (value == null) {
        options.splice(i, 1)
        if (options.length === 0) {
          delete eqs.question.options
        }
      }
      else {
        while (i > options.length - 1) {
          options.push('')
        }
        options[i] = value
      }
    })
  },


})))

