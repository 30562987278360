import { useRef, useCallback, forwardRef, memo } from 'react'
import { Select } from '@mantine/core'
import { useQuestionStore, QuestionLocation, Question, QuestionKind } from '../model/questions'

import { QuestionDisplay } from './QuestionDisplay'

export type QuestionOptionProps = {
  label: string
  value: string,
  used: boolean,
  question: Question,
}

export const QuestionOption = forwardRef<HTMLDivElement, QuestionOptionProps>(
  ({label, value, used, question, ...props}: QuestionOptionProps, ref) => {
    return <QuestionDisplay ref={ref} question={question} {...props} />
  })

const simpleOptionMatch = (input: string, item: QuestionOptionProps) => {
  const query = input.toLowerCase().trim()

  return item.value === input ||
    item.value.toLowerCase().includes(query) ||
    item.label.toLowerCase().includes(query)
}


export type QuestionSelectProps = {
  options: QuestionOptionProps[],
  kind: QuestionKind,
  location: QuestionLocation,
  value: string | null,
  onChange: (id: string) => void
}

export const QuestionSelect = memo(({options, value, kind, location, onChange}: QuestionSelectProps) => {
  const questions = useQuestionStore(state => state.questions)

  const editQuestion = useQuestionStore(state => state.editQuestion)

  const onCreate = useCallback((question: string) => {
    editQuestion({question: {question}, kind, location})
    return null
  }, [kind, location])


  const optionFilter = useCallback((input: string, item: QuestionOptionProps) =>
    (!item.used || item.value === value) && simpleOptionMatch(input, item)
  , [value])

  const ref = useRef<HTMLInputElement>(null)

  const onOpen = useCallback(() => {
    if (!ref.current) return
    console.log('ref!', ref.current);
    setTimeout(() => {
      ref.current!.setSelectionRange(0, ref.current!.value.length)
    }, 0)

  }, [ref])

  const onChange2 = useCallback((id: string) => {
    if (value === id) {
      if (id) {
        editQuestion({question: questions[id], kind, location, originalId: id})
      }
    }
    else {
      onChange(id)
    }
  }, [questions, kind, value, location])

  return (
    <Select
      ref={ref}
      placeholder="Choose or write question"
      itemComponent={QuestionOption}
      data={options}
      value={value}
      onChange={onChange2}
      searchable
      clearable
      creatable
      shadow="xl"
      allowDeselect={false}
      getCreateLabel={(query) => `+ Create "${query}"`}
      onCreate={onCreate}
      filterDataOnExactSearchMatch
      onDropdownOpen={onOpen}
      styles={{
        input: {
          '&:not(:focus)':{
            border: 'none',
            background: 'none',
          },
        },
        itemsWrapper: {
          padding: '16px',
        },
      }}
      maxDropdownHeight={400}
      nothingFound="No matching questions"
      filter={optionFilter}
    />
  )
})

