import create from 'zustand'

import { findTimeZone, getZonedTime } from 'timezone-support'

const tz = findTimeZone('Australia/Adelaide')

export type Gradient = {
  from: string,
  to: string,
}

export const GRADIENTS = {
  morning: { from: '#FFEC4F', to: '#FF4B4E' },
  day:     { from: '#C6FA8E', to: '#1347FF' },
  night:   { from: '#E433F5', to: '#1A1DFD' },
}

export type Hour = {
  start: number,
  gradient: keyof typeof GRADIENTS,
}

export const HOURS = [
  { start: 0,  gradient: 'night' },
  { start: 6,  gradient: 'morning' },
  { start: 11, gradient: 'day' },
  { start: 15, gradient: 'night' },
]

export interface GradientStore {
  gradient: Gradient,

  update: () => void
}

export const useGradientStore = create<GradientStore>()((set) => ({
  gradient: GRADIENTS.morning,

  update() {
    const time = getZonedTime(new Date(), tz)
    const currentHour = time.hours

    let gradient = GRADIENTS.morning
    for (const hour of HOURS) {
      if (hour.start > currentHour) {
        break
      }
      // console.log('picked hour', hour.start, hour.gradient)
      gradient = GRADIENTS[hour.gradient as keyof typeof GRADIENTS]
    }

    set(() => ({ gradient }))
  },
}))


export const useGradient = () => useGradientStore(state => state.gradient)
