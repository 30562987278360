import { forwardRef, memo } from 'react'
import { Card, Text } from '@mantine/core'

import { useQuestionStore, Question, QuestionKind } from '../model/questions'

export type QuestionDisplayProps = {
  question: Question
}

export const QuestionDisplay = memo(forwardRef<HTMLDivElement, QuestionDisplayProps>(
  ({question, ...props}: QuestionDisplayProps, ref) => {

  const editQuestion = useQuestionStore(state => state.editQuestion)

  const kind = {0: 'free', 2: 'binary', 5: 'likert'}[question.options?.length || 0] as QuestionKind || 'free'

  return (
    <Card
      ref={ref}
      sx={theme => ({
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.colors[theme.primaryColor][3],
        },
      })}
      onClick={() => editQuestion({question, kind, originalId: question.id})}
      {...props}
    >
      <Card.Section inheritPadding withBorder pl='xs' pt='6px' pb='3px'>
      <Text size='xs' sx={{fontFamily: 'monospace'}}>{question.id}</Text>
      </Card.Section>

      <Text mt='6px' size='sm'>{question.question}</Text>
    </Card>
  )
}))
