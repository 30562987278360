import { useCallback, useEffect, forwardRef } from 'react'
import { motion } from 'framer-motion'

import { Flex, Button, Paper, Title, Text } from '@mantine/core'

import type { FlexProps } from '@mantine/core'

import { useBlinds } from '../model/blinds'
import { useScreenStore } from '../model/screen'
import { useGradient } from '../model/gradients'

import { LightMode } from '../components/ColorMode'

import { useIdle } from '@mantine/hooks'

const CONSENT_IDLE_TIMEOUT = 30 * 1000

export const Consent = motion(forwardRef<HTMLDivElement, FlexProps>((props: FlexProps, ref) => {

  const decline = useScreenStore((state) => state.decline)
  const accept = useScreenStore((state) => state.accept)
  const prepareBlinds = useBlinds((state) => state.prepare)
  const gradient = useGradient()

  const maybeDecline = useCallback((ev: React.MouseEvent) => {
    if (ev.target !== ev.currentTarget) return
    decline()
  }, [])

  const onAccept = useCallback(() => {
    prepareBlinds()
    accept()
  }, [])

  const idle = useIdle(CONSENT_IDLE_TIMEOUT, { initialState: false })

  useEffect(() => {
    if (idle) decline()
  }, [idle])


  return (
    <Flex
      h="100%"
      direction="column"
      align="center"
      justify="center"
      pos="relative"
      onClick={maybeDecline}

      sx={{
        background: `linear-gradient(90deg, ${gradient.from}, ${gradient.to})`,
      }}

      ref={ref}
      {...props}
    >

      <LightMode>
      <Paper radius="lg" w="50%" h="50%" px="80px" py="100px" sx={{border: '2px solid black'}}>
        <Flex w="100%" h="100%" direction="column" align="center" justify="space-between">
          <Text size="xl" align="center">
            I acknowledge that I am over 15 years old and that my responses
            will be collected for research about people's ethical opinions.
          </Text>
          <Flex w="100%" justify="space-between" mx="xl">
              <Button
                fullWidth
                size="xl" radius="xl" mx="xl"
                bg="chakra-body-bg"
                sx={{
                  '&:active': {
                    borderColor: 'black',
                    color: 'black',
                  }
                }}
                onClick={onAccept}
              >
                Enter
              </Button>
          </Flex>
        </Flex>
      </Paper>
      </LightMode>
    </Flex>
  )
}))
