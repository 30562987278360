import create from 'zustand'

export type BindProps = {
  onChange: KeyboardStore["onChange"],
  onCancel: KeyboardStore["onCancel"],
  onDone: KeyboardStore["onDone"],
  label: string
  value: string,
}

export interface KeyboardStore {
  open: boolean,
  value: string,

  onChange: (value: string) => void,
  onCancel: () => void,
  onDone: () => void,

  bind: (props: BindProps) => void
  unbind: () => void,
  setValue: (value: string) => void,
}

export const useKeyboardStore = create<KeyboardStore>()((set) => ({
  open: false,
  onChange: () => {},
  onCancel: () => {},
  onDone: () => {},
  value: '',
  label: '',

  bind({onChange, onCancel, onDone, value, label}) {
    set(() => ({
      open: true,
      value,
      onChange,
      onCancel,
      onDone,
      label,
    }))
  },

  unbind() {
    set(() => ({
      open: false,
      onChange: () => {},
      onCancel: () => {},
      onDone: () => {},
    }))
  },

  setValue(value: string) {
    set(() => ({value}))
  }
}))
