import { useCallback, useState, useEffect, useMemo, useRef, forwardRef } from 'react'

import { Flex, Button, Title, Radio, Textarea, Modal, RingProgress, Stepper, Stack, useMantineTheme, Text, Box, keyframes, Transition } from '@mantine/core'
import type { FlexProps } from '@mantine/core'
import { motion } from 'framer-motion'

import { useIdle, useInterval } from '@mantine/hooks'

import { LightMode } from '../components/ColorMode'

import { useBlinds } from '../model/blinds'
import { useKeyboardStore } from '../model/keyboard'
import { useGradient } from '../model/gradients'

import { Keyboard } from '../components/Keyboard'
import Blinds from '../components/Blinds'

import { useScreenStore } from '../model/screen'
import { useQuestionStore, Question } from '../model/questions'

const IDLE_TIMEOUT_1 = 30*1000
const IDLE_TIMEOUT_2 = 30*1000
const QUESTION_COOLDOWN = 10*1000
const FREE_TEXT_MAX_LENGTH = 500

const keyboardLayout = {
default: [
  "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
  "{tab} q w e r t y u i o p [ ] \\",
  "{lock} a s d f g h j k l ; ' {enter}",
  "{shift} z x c v b n m , . / {shift}",
  "{cancel} {space} {accept}",
],
shift: [
  "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
  "{tab} Q W E R T Y U I O P { } |",
  '{lock} A S D F G H J K L : " {enter}',
  "{shift} Z X C V B N M < > ? {shift}",
  "{cancel} {space} {accept}",
],
}


// const keyboardLayout = {
// default: [
//   "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
//   "{tab} q w e r t y u i o p {bksp}",
//   "{lock} a s d f g h j k l {enter}",
//   "{shift} z x c v b n m , . {shift}",
//   ".com @ {space} {accept}",
// ],
// shift: [
//   "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
//   "{tab} Q W E R T Y U I O P { } |",
//   '{lock} A S D F G H J K L : " {enter}',
//   "{shift} Z X C V B N M < > ? {shift}",
//   ".com @ {space} {accept}",
// ],
// }

const buttonSymbols = {
  // '{bksp}': '⌫',
  // '{enter}': '⏎',
  '{bksp}': 'delete',
  '{enter}': 'return',
  '{accept}': 'done',
  '{cancel}': '⇩',
}

const keyboardStyleOverride = {
  borderRadius: 0,
  maxWidth: '100%',
}

const keyboardStyle = {
  '&.hg-theme-default': {
    backgroundColor: '#2B2B2B',
  },
  '&.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"]': {
    maxWidth: 'unset',
  },
  '&.hg-theme-default .hg-button.hg-standardBtn': {
    maxWidth: '130px',
  },
  '&.hg-theme-default .hg-button.hg-functionBtn.hg-button-tab': {
    flex: '1.5',
  },
  '&.hg-theme-default .hg-button.hg-functionBtn.hg-button-bksp': {
    flex: '1.5',
  },
  '&.hg-theme-default .hg-button.hg-functionBtn': {
    backgroundColor: '#4B494A',
    flex: '2',
  },
  '&.hg-theme-default .hg-button.hg-button-space': {
    flex: '6',
  },
  '&.hg-theme-default .hg-button.hg-button-shift': {
    flex: '3',
  },
  '&.hg-theme-default.shift-active .hg-button.hg-button-shift': {
    backgroundColor: '#6B6B6B',
  },
  '&.hg-theme-default.caps-active .hg-button.hg-button-lock': {
    backgroundColor: '#6B6B6B',
  },
  '&.hg-theme-default .hg-button': {
    fontSize: '32px',
    height: '100px',
    backgroundColor: '#6B6B6B',
    borderBottomColor: '#000000',
    color: 'white',
  },
  '&.hg-theme-default .hg-row': {
    gap: '12px',
    margin: '12px',
  }

}

export function BoundKeyboard() {
  const open = useKeyboardStore(state => state.open)
  const onChange = useKeyboardStore(state => state.onChange)
  const onDone = useKeyboardStore(state => state.onDone)
  const onCancel = useKeyboardStore(state => state.onCancel)
  const value = useKeyboardStore(state => state.value)
  const setValue = useKeyboardStore(state => state.setValue)
  const label = useKeyboardStore(state => state.label)

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const onInput = useCallback((newValue: string) => {
    setValue(newValue)
    onChange(newValue)
  }, [setValue, onChange])

  const onTextareaRef = useCallback((el: HTMLTextAreaElement) => {
    // console.log('el', el, 'open', open)
    if (open && el) {
      el.focus()
      el.selectionStart = el.value.length
      el.selectionEnd = el.value.length
    }
    textareaRef.current = el
  }, [open])

  const onTextInput = useCallback((ev: React.FormEvent<HTMLTextAreaElement>) => {
    onInput(ev.currentTarget.value)
  }, [onInput])

  return (
    <Transition mounted={open} transition="slide-up">
      {(styles) =>
        <Stack
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 2,
            width: '100%',
            height: '100%',
            display: 'flex',
            gap: 0,
            // flexDirection: 'column',
            // justifyContent: 'space-around'

            ...styles
          }}
        >
        <Textarea
          ref={onTextareaRef}
          placeholder={label}
          value={value}
          size="xs"
          inputMode="none"
          styles={{
            label: {
              fontWeight: 400,
            },
            wrapper: {
              height: '100%',
            },
            root: {
              flex: '1 1 auto',
            },
            input: {
              '&': {
                padding: '42px 46px',
              },
              height: '100%',
              borderRadius: 0,
              fontSize: '48px',
              border: 'none',
              zIndex: 1,
              '&::placeholder': {
                color: '#7D7C7D',
              },
            },
          }}

          maxLength={FREE_TEXT_MAX_LENGTH}
          onChange={onTextInput}
        />
        <Keyboard
          onChange={onInput}
          onAccept={onDone}
          onCancel={onCancel}
          value={value}
          display={buttonSymbols}
          mergeDisplay
          preventMouseDownDefault
          newLineOnEnter
          maxLength={FREE_TEXT_MAX_LENGTH}
          layout={keyboardLayout}
          textarea={textareaRef.current}
          style={keyboardStyleOverride}
          sx={keyboardStyle}
          // buttonTheme={[
          //   // {
          //   //   class: 'hg-enterBtn',
          //   //   buttons: '{enter}',
          //   // },
          //   {
          //     class: 'hg-standardBtn',
          //     buttons: '@',
          //   },
          // ]}
        />
        </Stack>
      }
    </Transition>
  )
}
